import { Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'

import logoImage from '../../images/logo.svg'
import Button from '../Button'

const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-top: 14px;
  margin-bottom: 114px;
  flex-wrap: wrap;
`

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media (min-width: 726px) {
    flex-wrap: nowrap;
  }
`

const NavList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const NavItem = styled.li`
  margin-right: 50px;
`

const StyledLink = styled(Link)`
  padding: 15px 0;
  font-size: ${props => props.theme.typography.bodySizes.base};
  color: ${props => props.theme.colours.black};
  text-decoration: none;
  transition: border 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
  border-bottom: solid 3px transparent;
  &:hover {
    border-bottom: solid 3px ${props => props.theme.colours.fieryRose};
  }
`

const SecondaryNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  a:first-child {
    padding: 15px 0;
    margin-right: 16px;
    font-size: ${props => props.theme.typography.bodySizes.base};
    color: ${props => props.theme.colours.black};
    text-decoration: none;
    border-bottom: solid 3px transparent;
  }
`

const Header = () => (
  <StyledHeader>
    <div>
      <img src={logoImage} style={{ maxWidth: '150px' }} />
    </div>
    <Nav>
      <NavList>
        <NavItem>
          <StyledLink to="/features">Features</StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/pricing">Pricing</StyledLink>
        </NavItem>
        <NavItem>
          <StyledLink to="/help/faqs">FAQs</StyledLink>
        </NavItem>
      </NavList>
    </Nav>
    <SecondaryNav>
      <Link to="/login">Log in</Link>
      <Button to="/room" as={Link} variant="secondary" size="small">
        Get started
      </Button>
    </SecondaryNav>
  </StyledHeader>
)

export default Header
