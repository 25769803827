/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'

import './layout.css'
import { Helmet } from 'react-helmet'

import Header from './Header'

const Layout = ({ children, showHeader = true }) => {
  return (
    <>
      <Helmet>
        {/* https://csswizardry.com/2020/05/the-fastest-google-fonts/ */}
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          rel="preload"
          as="style"
          href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Finger+Paint&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Finger+Paint&display=swap"
          media="print"
          onload="this.media='all'"
        />
      </Helmet>
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1440,
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        {showHeader && <Header />}
        <main>{children}</main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
