import styled from '@emotion/styled'

const Button = styled.button`
  ${props => `
    appearance: none;
    background: ${props.theme.colours.fieryRose};
    border-radius: 999px;
    border: none;
    color: ${props.theme.colours.white};
    cursor: pointer;
    display: inline-block;
    font-family: ${props.theme.fonts.sansSerif};
    font-size: ${props.size === 'small' ? '14' : '18'}px;
    line-height: ${props.size === 'small' ? '14' : '18'}px;
    padding: ${props.size === 'small' ? '12px 25px' : '17px 61px'};
    text-decoration: none;

    ${
      props.variant === 'secondary'
        ? `
      background: ${props.theme.colours.white};
      border: solid 2px ${props.theme.colours.fieryRose};
      color: ${props.theme.colours.fieryRose};
    `
        : ''
    }

    ${
      props.variant === 'tertiary'
        ? `
      background: ${props.theme.colours.white};
      border: solid 2px ${props.theme.colours.black};
      color: ${props.theme.colours.black};
    `
        : ''
    }
  `}
`

export default Button
